export function User() {
    const { localStorage } = window;

    const session = () => {
        const { user } = localStorage
        if (user !== undefined) {
            return JSON.parse(user);
        }
        const newUser = {
            uid: window.crypto.randomUUID !== undefined ? window.crypto.randomUUID() : Math.round(Math.random()*10000000000),
            name: 'human_user'
        };
        localStorage.setItem('user', JSON.stringify(newUser));
        return newUser;
    }

    return {
        session
    }
}
