import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export default function LicuHeader() {
    const { t } = useTranslation();

    return (
        <header className="App-header">
            <h1>
                <Link to="/">
                    <img src="/licu.svg" alt="LICU" />
                    Li<span className='logo-cu'>cu</span>
                </Link>
            </h1>
            <nav>
                <ul>
                    <li><Link to="/blog">{t('app.menu.blog')}</Link></li>
                    <li><a href="/#contact" className="main-action">{t('app.menu.contact')}</a></li>
                </ul>
            </nav>
        </header>
    );
}
