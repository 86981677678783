import React  from 'react';
import { Link, Outlet } from 'react-router-dom';
import './blog.scss';
import './prism.css';
import SEO from '../commons/SEO';

export default function Blog() {
    SEO({title: 'Blog'});

    return (
        <>
            <h2 className="main-section" ><Link to="/blog">Blog</Link></h2>
            <div className="blog-body">
                <Outlet />
            </div>
        </>
    );
}

