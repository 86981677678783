import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import * as es_app from './i18n/es.app.json';
import * as en_app from './i18n/en.app.json';
import * as es_services from './i18n/es.services.json';
import * as en_services from './i18n/en.services.json';
import * as es_workflow from './i18n/es.workflow.json';
import * as en_workflow from './i18n/en.workflow.json';
import * as es_survey from './i18n/es.survey.json';
import * as en_survey from './i18n/en.survey.json';
import * as es_clients from './i18n/es.clients.json';
import * as en_clients from './i18n/en.clients.json';
import * as es_blog from './i18n/es.blog.json';
import * as en_blog from './i18n/en.blog.json';

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
		resources: {
			en: {
				translation: {
					app: en_app,
					services: en_services,
					workflow: en_workflow,
                    survey: en_survey,
                    clients: en_clients,
                    blog: en_blog,
				}
			},
			es: {
				translation: {
					app: es_app,
					services: es_services,
					workflow: es_workflow,
                    survey: es_survey,
                    clients: es_clients,
                    blog: es_blog,
				}
			}
		},
		interpolation: {
			escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		}
	});

export default i18n;
