import { useEffect, useRef } from "react";

interface SEOProps {
    title: string
}

export default function SEO({ title }: SEOProps) {
    const originTitle = useRef(window.document.title);

    useEffect(() => {
        const origin = originTitle.current;

        if (title) {
            window.document.title = `${title} | Licu`;
        }

        return () => {
            window.document.title = origin;
        }
    }, [title]);
}
