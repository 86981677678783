import './linkedin.scss';

interface LinkedInProps {
    name: string
    srcImage: string
    link: string
}

export default function LinkedIn({ name, srcImage, link}: LinkedInProps) {
    return <a target="_blank" className="linkedin" href={link} rel="noreferrer">
        <span className="linkedin-image"><img src={srcImage} alt={name} /></span>
        <span className="name">{name} </span>
    </a>
}
