import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './i18n';
import './App.scss';
import Chatbot from './chatbot/Chatbot';
import LicuHeader from './LicuHeader/LicuHeader';

function App() {
    const email = "info@licu.es";
    const phone = "+34 604131567";
    const { t } = useTranslation();

    return (
        <div className="App">
            <LicuHeader />

            <div className="content">
                <Outlet />

                <footer>
                    <div>
                        <div className="title">{t('app.footer.contact_information')}</div>
                        <a href={`mailto:${email}`}>
                            <span className="material-icons-outlined">
                                email
                            </span>
                            {email}
                        </a>
                        <a href={`tel:${phone}`}>
                            <span className="material-icons-outlined">
                                phone
                            </span>{phone}
                        </a>
                    </div>

                    <div>
                        <div className="title">{t('app.footer.sections.title')}</div>
                        <a href="/#services">{t('app.footer.sections.items.services')}</a>
                        <a href="/#workflow">{t('app.footer.sections.items.our_workflow')}</a>
                        <a href="/#contact">{t('app.footer.sections.items.contact')}</a>
                    </div>
                </footer>
            </div>
            <Chatbot />
        </div>
    );
}

export default App;
