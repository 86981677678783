import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkedIn from '../../../commons/LinkedIn/LinkedIn';
import Post from '../Post';
import './author.scss';

interface AuthorProps {
    post: Post
}
export default function Author({ post }: AuthorProps) {
    const { t } = useTranslation();

    return (
        <div className="author">
            <LinkedIn name={post.author.name} srcImage={post.author.image} link={post.author.link} />
            {t('blog.list.on')} {post.published}
        </div>
    )
}
