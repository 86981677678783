import React, { useState } from 'react';
import './Survey.scss';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { database } from '../firebase';
import { ref, set } from 'firebase/database';

interface ISurvery {
    companyName: string;
    website?: string;
    name: string;
    email: string;
    hasVendor?: boolean;
    hasCustomer?: boolean;
}

export default function Survey() {
    const { t } = useTranslation();
    const [showAdditionalInfo, setAdditionalInfo] = useState(false);
    const [dataForm, setDataForm] = useState<ISurvery>({ companyName: '', name: '', email: '' });
    const [showSurvey, setShowSurvey] = useState<boolean>(true);
    const [surveySended, setSurveySended] = useState<boolean>(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [formErrors, setFormErrors] = useState<Array<string>>([]);


    const handlerUpdateData = ({ target }: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const updatedData: ISurvery = { ...dataForm };
        const name: string = target.name;
        // @ts-ignore
        updatedData[name] = target.type === 'checkbox' ? target.checked : target.value;
        setDataForm(updatedData);
        validateForm(updatedData);
    }

    const sendSurvey = async () => {
        if (isValidForm) {
            // @ts-ignore
            const surveys = ref(database, `surveys/${dataForm.email.replaceAll('.', '_').replace('@', '__')}`)

            set(surveys, dataForm);
            setShowSurvey(false);
            setSurveySended(true);
        }
    }

    const validateForm = (data: ISurvery) => {
        const errors: Array<string> = [];
        const required = ["companyName", "name", "email"];
        const labels = {
            companyName: 'nombre de empresa',
            name: 'nombre',
            email: 'correo electrónico',
            projectDescription: 'descripción del projecto',
            expected: 'resultados deseados'
        }
        required.forEach(req => {
            // @ts-ignore
            if (!data[req] || data[req].trim().length === 0) {
                // @ts-ignore
                errors.push(labels[req]);
            }
        });
        setFormErrors(errors);
        setIsValidForm(errors.length === 0);
    }

    return (
        <section className="survey" id="contact">
            <a href="#workflow">
                <span className="material-icons-outlined">
                    keyboard_arrow_up
                </span>
            </a>
            <h2>{t('survey.title')}</h2>
            {showSurvey ? (<>
                <p>{t('survey.description')}</p>

                {formErrors.length > 0 ? (
                    <div className="errors">
                        {t('survey.error')}:
                        <ul className="errorsList">
                            {formErrors.map(error => <li key={error}>{error}</li>)}
                        </ul>
                    </div>)
                    : <></>}

                <div className="wizard">

                    <div className="step">
                        <div>{t('survey.fields.company_name')} *
                            <input type="text" placeholder="company" name="companyName" onChange={handlerUpdateData} />
                        </div>
                        <div>{t('survey.fields.website')}
                            <input type="text" placeholder="www.company.com" name="website" onChange={handlerUpdateData} />
                        </div>
                        <div>{t('survey.fields.name')} *
                            <input type="text" placeholder="Jesus Sanchez" name="name" onChange={handlerUpdateData} />
                        </div>
                        <div>{t('survey.fields.phone')}
                            <input type="text" placeholder="+34 666666666" name="phone" onChange={handlerUpdateData} />
                        </div>
                        <div>{t('survey.fields.email')} *
                            <input type="text" placeholder="my@company.com" name="email" onChange={handlerUpdateData} />
                        </div>
                    </div>
                    <div className="step">
                        <div>
                            <label>{t('survey.fields.message.name')} *</label>
                            <textarea
                                placeholder={t('survey.fields.message.placeholder')}
                                name="message"
                                onChange={handlerUpdateData} />
                        </div>
                        <div>
                            <label>{t('survey.fields.additional_info')}</label>
                            <span>
                                <Switch
                                    checked={showAdditionalInfo}
                                    onClick={() => setAdditionalInfo(!showAdditionalInfo)}
                                    inputProps={{ 'aria-label': 'controlled' }} />
                            </span>
                        </div>
                        {showAdditionalInfo && (
                            <>
                                <div>
                                    <label>{t('survey.fields.to_do.name')}</label>
                                    <textarea
                                        placeholder={t('survey.fields.placeholder')}
                                        name="projectDescription"
                                        onChange={handlerUpdateData} />
                                </div>
                                <div>
                                    <label>{t('survey.fields.expected_resutls.name')}</label>
                                    <textarea
                                        placeholder={t('survey.fields.expected_resutls.placeholder')}
                                        name="expected"
                                        onChange={handlerUpdateData}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="toolbar">
                        <button onClick={sendSurvey} className={isValidForm ? '' : 'disabled'}>{t('survey.send_button')}</button>
                    </div>
                </div>
            </>) : <></>}
            {surveySended ? (
                <div className="sended-message">
                    {t('survey.sended_message')}
                </div>
            ) : <></>}

            <a href="#clients">
                <span className="material-icons-outlined">
                    keyboard_arrow_down
                </span>
            </a>
        </section>
    );
}
