import {useTranslation} from 'react-i18next';
import './Clients.scss';

export default function Clients() {
    const { t } = useTranslation();
    const imgs = [
        'clients/vindoo/vindoo.jpg',
        'clients/vindoo/vindoo-sol.jpg',
        'clients/vindoo/vindoo-use.jpg',
        'clients/vindoo/vindoo-val.jpg',
        'clients/vindoo/vindoo-con.jpg'
    ];
    const mins = [
        'clients/vindoo/vindoo-mobile-hom.webp'
    ];
    const calculateWidth = (images: NodeListOf<HTMLImageElement>, index: number) => {
        let total = 0;
        images.forEach((image, imgIndex) => {
            if (imgIndex < index) {
                
            total += image.offsetWidth;
            console.log(image.offsetWidth, imgIndex)
            }
        })
        return total;
    };

    function moveCarousel (index: number) {
        const carousel: HTMLDivElement | null = document.querySelector('.carousel>div');
        const imgs: NodeListOf<HTMLImageElement> = document.querySelectorAll('.carousel img');
        const width = calculateWidth(imgs, index);

        if (carousel){
            carousel.style.transform = `translate(-${width}px)`;
        }
    }

    return <section className="clients" id="clients">
        <a href="#contact">
            <span className="material-icons-outlined">
                keyboard_arrow_up
            </span>
        </a>

        <h2>{t('clients.title')}</h2>
        <div className="content">
            <div className="client">
                <div className="carousel desktop">
                    <div>
                        {imgs.map(img => <img key={img} src={img} alt="vindoo" />)}
                    </div>
                    <div className="controls">
                        {imgs.map((img, index) => <span key={index} onClick={()=> moveCarousel(index)}>{index}</span>)}
                    </div>
                </div>
                
                <div className="carousel mobile">
                    <div>
                        {mins.map(
                            img => <img key={img} src={img} alt="vindoo mobile" />
                        )}
                    </div>
                </div>

                <div className="title"><a href="https://www.vindoo.ai" target="_blan_blan_blan_blan_blan_blankkkkkk">Vindoo</a></div>
            </div>
        </div>
        
    </section>
}
