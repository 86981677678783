import React, { useEffect, useState } from 'react';
import { onValue, ref } from 'firebase/database';
import { database } from '../../firebase';
import { Link } from 'react-router-dom';
import { AppstoreAddOutlined, WechatOutlined } from '@ant-design/icons';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import './list.scss';
import Author from './Author/Author';
import Post, {Traductions} from './Post';


export default function List() {
    const [language, setLanguage] = useState("en");
    const [posts, setPosts] = useState<Post[] | never>([]);
    const images = (postId: string, classImage: string) => {
        const arr: any = {
            "web_applications": <AppstoreAddOutlined className={classImage} />,
            "chatbot": <WechatOutlined className={classImage} />
        };

        return arr[postId];
    }
    const { t } = useTranslation();

    useEffect(() => {
        const langDetector = new LanguageDetector();
        const tmpLanguage = langDetector.detectors.navigator.lookup()[0].slice(0, 2);
        setLanguage(tmpLanguage);
    }, []);

    useEffect(() => {
        const blog = ref(database, "blog/posts");

        return onValue(blog, (snapshot: any) => {
            const data = snapshot.val();
            if (data) {
                const newArray: Post[] = Object.values(data);
                newArray.sort((current, prev) => {
                    const currentDate = current.published.split('/').reverse().join();
                    const prevDate = prev.published.split('/').reverse().join();
                    const currentNum = parseInt(currentDate);
                    const prevNum = parseInt(prevDate);
                    return currentNum > prevNum ? 1 : -1;
                });
                setPosts(newArray);
            }
        },);
    }, []);

    const stringToUrl = (text: string) => {
        return text.normalize("NFD").replaceAll(/[\u0300-\u036f]/g, '').replaceAll(" ", "-");
    }

    return <>
        {posts.map((post: Post) => (<div key={post.id} className="post">
            <article key={post.id}>
                {images(post.id, "post-image")}
                <h2>
                    <Link to={`posts/${post.id}/${stringToUrl(post.title[language as keyof Traductions])}`}>{post.title[language as keyof Traductions]}</Link>
                </h2>
                <Author post={post} />
                <div className="tags">
                    {post.tags.map((tag: string) => <Tag key={tag} bordered={false} color="purple">{tag}</Tag>)}
                </div>
            </article>
        </div>))}
        {posts.length === 0 ? <article className="empty-list">{t('app.commons.empty_list')}</article> : ''}
    </>
}
