import {
    createBrowserRouter,
} from "react-router-dom";
import App from './App';
import Home from './Home';
import Blog from './Blog/Blog';
import Posts, { postLoader } from "./Blog/Posts/Posts";
import List from "./Blog/Posts/List";
import Error from "./commons/Error";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Error />,
        children: [
            {
                path: "blog",
                element: <Blog />,
                children: [
                    {
                        path: "",
                        element: <List />
                    },
                    {
                        loader: postLoader,
                        path: "posts/:id/:title",
                        element: <Posts />
                    }
                ]
            },
            {
                path: "",
                element: <Home />,
            },
        ],
    },
]);

export default router;
