import { useEffect, useState } from "react";
import { Button, FloatButton, Spin } from "antd";
import { RobotOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import './chatbot.scss';
import { User } from "../commons/User";

function Chatbot() {
    const [showChatbot, setShowChatbot] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [newText, setNewText] = useState("");
    const [aiData, setAiData] = useState({});
    const [username] = useState('unknown');
    const [user, setUser] = useState();
    const [waitingResponse, setWaitingResponse] = useState(false);
    const [backError, setBackError] = useState(false);

    useEffect(() => {
        if (newText !== '') {
            const timestamp = Date.now();
            setChatHistory([...chatHistory, { text: newText, username: username, timestamp }]);
            setNewText('');
            scrollHistory();
        }

    }, [newText, username])

    useEffect(() => {
        if (aiData?.text !== undefined) {
            const timestamp = Date.now();
            setChatHistory([...chatHistory, { ...aiData, timestamp }]);
            scrollHistory()
        }
    }, [aiData])

    useEffect(() => {
        const objUser = User();
        setUser(objUser.session());
    }, []);

    const scrollHistory = () => {
        setTimeout(() => {
            const chatHistoryDiv = document.querySelector('.chatHistory');
            chatHistoryDiv.scrollTo(0, chatHistoryDiv.scrollHeight);
        }, 10);
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        setWaitingResponse(true);
        setBackError(false);
        const tmpText = e.target.prompt.value;
        const chatHost = '/chatbot/chat';
        setNewText(tmpText);
        e.target.prompt.value = '';
        const response = await fetch(`${chatHost}`, {
            method: 'POST',
            cache: 'no-cache',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({ text: tmpText, uid: user.uid, username: user.name })
        }).catch(() => {
            setWaitingResponse(false);
            setBackError(true);
        });

        if (response) {
            response.json().then(a => {
                setAiData(a);
                setWaitingResponse(false);
            });
        }
    }

    const toggleChat = () => {
        setShowChatbot(!showChatbot);
    }

    return (
        <>
            <FloatButton
                icon={<RobotOutlined />}
                onClick={toggleChat}
                shape="circle"
                className={showChatbot ? 'hide-chatbot-button' : ''}
            />
            {(showChatbot) ?
                <div className="chatbot">
                    <h3>LicuAI
                        <Button shape="link" icon={<CloseOutlined />} onClick={toggleChat}></Button>
                    </h3>
                    <div className="chatHistory">
                        {
                            chatHistory.map(({ text, username, timestamp }) => {
                                const classAlign = username === 'licuai' ? 'left' : 'right';
                                return <div key={timestamp} className={classAlign}>
                                    <div className="message">
                                        {text}
                                    </div>
                                </div>;
                            })
                        }
                        <Spin spinning={waitingResponse} />
                        {(backError) ? (<div className="error"><ExclamationCircleOutlined /> Error de comunicación. Vuelva a intente más tarde. Disculpe las molestias.</div>) : ''}
                    </div>
                    <div className="prompt">
                        <form method="post" onSubmit={sendMessage} >
                            <input type="text" name="prompt" placeholder="Prompt" />
                            <button type="submit" >Enviar</button>
                        </form>
                    </div>

                </div> : ''}
        </>
    );
}

export default Chatbot;
