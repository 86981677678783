import React, { useEffect } from 'react';
import Survey from './Survey/Survey';
import Services from './Services/Services';
import Workflow from './Workflow/Workflow';
import Clients from './Clients/Clients';
import SEO from './commons/SEO';
import { useTranslation } from 'react-i18next';

export default function Home() {
    const { t } = useTranslation();
    SEO({ title: t('app.title') });

    useEffect(() => {
        const reg = RegExp(/#contact$/);
        if (reg.test(window.location.href)) {
            const contact = document.querySelector("#contact");
            contact?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [])

    return <>
        <Services />
        <Workflow />
        <Survey />
        <Clients />
    </>;
}
