// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDaxp9M5sIWLg6na0c6gKeiTBImszNOcOI",
    authDomain: "webpage-e7a28.firebaseapp.com",
    projectId: "webpage-e7a28",
    storageBucket: "webpage-e7a28.appspot.com",
    messagingSenderId: "568691780322",
    appId: "1:568691780322:web:b017afeaf62a60d8b68cbb",
    measurementId: "G-SFZ297M7GQ",
    databaseURL: "https://webpage-e7a28-default-rtdb.europe-west1.firebasedatabase.app/"
};

if (window.location.hostname === 'localhost') {
    firebaseConfig.databaseURL =  'http://127.0.0.1:9000/?ns=webpage-e7a28';
}
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);

