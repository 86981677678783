import React from 'react';
import { useTranslation } from 'react-i18next';
import './Workflow.scss';

export default function Workflow() {
    const { t } = useTranslation();

    return (
        <section className="workflow" id="workflow">
            <a href="#services">
                <span className="material-icons-outlined">
                    keyboard_arrow_up
                </span>
            </a>
            <h2>{t('workflow.title')}</h2>
            <div className="steps">
                <div className="workflow-step">
                    <div className="nro">1</div>
                    <div className="box">{t('workflow.step1.title')}</div>
                    <div className="description">
                        {t('workflow.step1.description1')} <a href="#contact">{t('workflow.step1.form')}</a> {t('workflow.step1.description2')}
                    </div>
                </div>
                <div className="workflow-step">
                    <div className="nro">2</div>
                    <div className="box">{t('workflow.step2.title')}</div>
                    <div className="description">
                        {t('workflow.step2.description')}
                    </div>
                </div>
                <div className="workflow-step">
                    <div className="nro">3</div>
                    <div className="box">{t('workflow.step3.title')}</div>
                    <div className="description">
                        {t('workflow.step3.description')}
                    </div>
                </div>
                <div className="workflow-step">
                    <div className="nro">4</div>
                    <div className="box">{t('workflow.step4.title')}</div>
                    <div className="description">
                        {t('workflow.step4.description')}
                    </div>
                </div>
                <div className="workflow-step">
                    <div className="nro">5</div>
                    <div className="box">{t('workflow.step5.title')}</div>
                    <div className="description">{t('workflow.step5.description')}</div>
                </div>
            </div>
            <a href="#contact">
                <span className="material-icons-outlined">
                    keyboard_arrow_down
                </span>
            </a>
        </section>
    )
}
