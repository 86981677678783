import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Services.scss';

export default function Services() {
    const { t } = useTranslation();
    const [showReadMore, setShowReadMore] = useState([false, false]);
    const services = [{
        name: t('services.dev_app.title'),
        subtitle: t('services.dev_app.highlight'),
        description: t('services.dev_app.description'),
    },
    {
        name: t('services.ml.title'),
        subtitle: t('services.ml.highlight'),
        description: t('services.ml.description')
    }
    ]

    const readMore = (index: number) => {
        const newState = [...showReadMore];
        newState[index] = showReadMore[index] !== null ? !showReadMore[index] : true;
        setShowReadMore(newState);
        return true;
    }

    return (
        <section className="service" id="services">
            {services.map((service, index) => (
                <div key={index} className="service">
                    <h2>{service.name}</h2>
                    <p>
                        <strong>{service.subtitle} </strong>
                        <span className={showReadMore[index] ? '' : 'detail'}>
                            {service.description}
                        </span>
                        <span className="link" onClick={() => readMore(index)}> {t('services.show')} {showReadMore[index] ? t('services.less') : t('services.more')}...</span>
                    </p>

                    {(index === 0) ?
                        <a className="main-action btn" href="#workflow">{t('services.our_worklow')}</a>
                        : <></>}
                </div>
            )
            )}
        </section>);
}
