import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import LicuHeader from '../LicuHeader/LicuHeader';
import './error.scss';

export interface ErrorRoute {
    data: string
    error: {
        message: string
        stack: string
    }
    internal: boolean
    status: number
    statusText: string
};

export default function Error() {
    const error = useRouteError();
    console.log(error);

    return <>
        <LicuHeader />

        <div className="error-page">
            <p>Internal Error!</p>
            <Link to="/">Go home</Link>
        </div>
    </>
}
