import React, { useEffect, useState } from 'react';
import LanguageDetector from 'i18next-browser-languagedetector';
import { marked } from 'marked';
import { get, ref } from 'firebase/database';
import { useLoaderData } from 'react-router-dom';
import Prism from 'prismjs';
import { database } from '../../firebase';
import Post, { Traductions } from './Post';
import './posts.scss';
import Author from './Author/Author';
import SEO from '../../commons/SEO';

export default function Posts() {
    const [language, setLanguage] = useState("");
    const [post, setPost] = useState({ content: '' });
    const postMetadata: Post | any = useLoaderData()
    SEO({ title: postMetadata.title[language as keyof Traductions] });

    useEffect(() => {
        Prism.highlightAll();
    });

    useEffect(() => {
        const langDetector = new LanguageDetector();
        const tmpLanguage = langDetector.detectors.navigator.lookup()[0].slice(0, 2);
        setLanguage(tmpLanguage);
    }, []);

    useEffect(() => {
        async function myImport() {
            if (language !== "") {
                const content = await import(`./${postMetadata.id}_${language}.md`);
                return { file: postMetadata.id, content };
            }
        }

        async function getHtml(url: any) {
            if (url !== undefined) {
                const content = await fetch(url.content.default);
                const r: any = { content: marked(await content.text()) }
                return r;
            }
        }

        const initContent = async () => {
            const tmpPost = await myImport();
            if (tmpPost !== undefined) {
                const res = await getHtml(tmpPost)
                setPost({ content: res.content });
            }
        }
        initContent()
    }, [language, postMetadata.id]);

    const postHeader = () => {
        return <>
            <h2>{postMetadata.title[language]}</h2>
            <Author post={postMetadata} />
        </>
    }

    return <>
        <article className="post">
            {postHeader()}
            <span dangerouslySetInnerHTML={{ __html: post.content }}></span>
        </article>
    </>
}

export async function postLoader({ params }: any) {
    const blog = ref(database, `blog/posts`);
    const posts: any = (await get(blog)).val();

    return posts[params.id];
}
